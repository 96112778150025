import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract, pontoVirgula } from '../../../util/util';
import { toast } from 'react-toastify';
import ItemChecklist from '../../../components/ItemChecklist';

export default function AlterarEventoChecklist({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [reload, setReload] = useState(1);
    const history = useHistory();

    const buscar = () => {
        api({
            method: "get",
            action: '/eventoChecklist/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = response.data.data;
            setDados(dadosRecebidos);
        }).catch(err => {
            toast.error('Erro ao carregar dados: ' + err.response.data.error);
        });
    };

    const validar = () => {
        /*
        var tipo = document.querySelector("#tipo");
        if (dados.tipo === undefined || dados.tipo === '') {
            toast.error('É necessário preencher o tipo da carta');
            setTimeout(() => {
                tipo.focus();
            }, 500);
            return false;
        }
        */
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        //update
        api({
            method: 'put',
            action: '/eventoChecklist/' + id,
            token: token,
            data: {
                ...dados,
            }
        })
            .then(function (response) {
                //console.log(response.data);
                history.push("/checklist");
                //toast.success("As alterações foram salvas com sucesso!");
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Checklist - {dados.evento?.nome ?? ""} - {data(dados.evento?.data)}</h3>
                    <h3>{dados.evento?.cliente?.nome ?? ""}</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Atividades</label>
                                                        <div>
                                                            <ItemChecklist dados={dados} stateChanger={setReload} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}