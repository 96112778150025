import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import LoginEvento from './pages/LoginEvento';
import LoginConfirmarDados from './pages/LoginConfirmarDados';
import LoginRapido from './pages/LoginRapido';
import Registro from './pages/Registro';
import Principal from './pages/Principal';
import Graficos from './pages/Graficos';

import PainelEvento from './pages/Evento/PainelEvento';
import CadastroEvento from './pages/Evento/CadastroEvento';
import CadastroEventoPergunta from './pages/Evento/CadastroEventoPergunta';
import CadastroEventoSatisfacaoPergunta from './pages/Evento/CadastroEventoSatisfacaoPergunta';

import PainelEventoPergunta from './pages/Evento/PainelEventoPergunta';
import PainelEventoSatisfacaoPergunta from './pages/Evento/PainelEventoSatisfacaoPergunta';

import PainelBriefing from './pages/Briefing/PainelBriefing';
import ListaBriefingCliente from './pages/Briefing/ListaBriefingCliente';
import PainelBriefingCliente from './pages/Briefing/PainelBriefingCliente';
import CadastroModeloBriefing from './pages/Briefing/CadastroModeloBriefing';
import VisualizarBriefing from './pages/Briefing/VisualizarBriefing';
import CadastroBriefingAdvisoryBoard from './pages/Briefing/CadastroBriefingAdvisoryBoard';
import CadastroBriefingEventoTerceiro from './pages/Briefing/CadastroBriefingEventoTerceiro';
import CadastroBriefingOrganizadoSede from './pages/Briefing/CadastroBriefingOrganizadoSede';
import CadastroBriefingOrganizadoForcaVendas from './pages/Briefing/CadastroBriefingOrganizadoForcaVendas';

import PainelRsvp from './pages/Rsvp/PainelRsvp';

import PainelProduto from './pages/Produto/PainelProduto';
import CadastroProduto from './pages/Produto/CadastroProduto';
import PainelProdutoGrupo from './pages/ProdutoGrupo/PainelProdutoGrupo';
import CadastroProdutoGrupo from './pages/ProdutoGrupo/CadastroProdutoGrupo';
import PainelProdutoTipoAtributo from './pages/ProdutoTipoAtributo/PainelProdutoTipoAtributo';
import CadastroProdutoTipoAtributo from './pages/ProdutoTipoAtributo/CadastroProdutoTipoAtributo';
import PainelProdutoAtributo from './pages/ProdutoAtributo/PainelProdutoAtributo';
import CadastroProdutoAtributo from './pages/ProdutoAtributo/CadastroProdutoAtributo';

import PainelProdutoOrcamento from './pages/Produto/PainelProdutoOrcamento';
import CadastroProdutoOrcamento from './pages/Produto/CadastroProdutoOrcamento';

import PainelServicoOrcamento from './pages/Produto/PainelServicoOrcamento';
import CadastroServicoOrcamento from './pages/Produto/CadastroServicoOrcamento';

import PainelRhCargo from './pages/RhCargo/PainelRhCargo';
import CadastroRhCargo from './pages/RhCargo/CadastroRhCargo';

import PainelCliente from './pages/Cliente/PainelCliente';
import CadastroCliente from './pages/Cliente/CadastroCliente';
import PainelFornecedor from './pages/Fornecedor/PainelFornecedor';
import CadastroFornecedor from './pages/Fornecedor/CadastroFornecedor';
import PainelEmpresa from './pages/Empresa/PainelEmpresa';
import CadastroEmpresa from './pages/Empresa/CadastroEmpresa';
import PainelPessoa from './pages/Pessoa/PainelPessoa';
import CadastroPessoa from './pages/Pessoa/CadastroPessoa';
import PainelSla from './pages/Sla/PainelSla';
import CadastroSla from './pages/Sla/CadastroSla';
import PainelSlaModelo from './pages/SlaModelo/PainelSlaModelo';
import CadastroSlaModelo from './pages/SlaModelo/CadastroSlaModelo';
import PainelVenda from './pages/Venda/PainelVenda';
import CadastroVenda from './pages/Venda/CadastroVenda';
import PainelOrcamento from './pages/Orcamento/PainelOrcamento';
import CadastroOrcamento from './pages/Orcamento/CadastroOrcamento';
import CadastroPagamentoOrcamento from './pages/Orcamento/CadastroPagamentoOrcamento';
import PainelCompra from './pages/Compra/PainelCompra';
import CadastroCompra from './pages/Compra/CadastroCompra';
import PainelSlaTipo from './pages/SlaTipo/PainelSlaTipo';
import CadastroSlaTipo from './pages/SlaTipo/CadastroSlaTipo';
import PainelSlaCompromisso from './pages/SlaCompromisso/PainelSlaCompromisso';
import CadastroSlaCompromisso from './pages/SlaCompromisso/CadastroSlaCompromisso';
import PainelEventoChecklistAtividade from './pages/Evento/ChecklistModelo/Atividade/PainelEventoChecklistAtividade';
import CadastroEventoChecklistAtividade from './pages/Evento/ChecklistModelo/Atividade/CadastroEventoChecklistAtividade';
import PainelSlaGrafico from './pages/SlaGrafico/PainelSlaGrafico';
import PainelFinanceiro from './pages/Financeiro/PainelFinanceiro';
import PainelParcelaPagar from './pages/Financeiro/Parcela/Pagar/PainelParcelaPagar';
import CadastroParcelaPagar from './pages/Financeiro/Parcela/Pagar/CadastroParcelaPagar';
import PainelParcelaReceber from './pages/Financeiro/Parcela/Receber/PainelParcelaReceber';
import CadastroParcelaReceber from './pages/Financeiro/Parcela/Receber/CadastroParcelaReceber';
import PainelPlanoConta from './pages/Financeiro/PlanoConta/PainelPlanoConta';
import CadastroPlanoConta from './pages/Financeiro/PlanoConta/CadastroPlanoConta';
import PainelCaixa from './pages/Financeiro/Caixa/PainelCaixa';
import CadastroCaixa from './pages/Financeiro/Caixa/CadastroCaixa';
import RelatorioCompraVenda from './pages/Venda/RelatorioCompraVenda';
import RelatorioCompra from './pages/Venda/RelatorioCompra';
import RelatorioFinanceiroParcela from './pages/Financeiro/Parcela/RelatorioFinanceiroParcela';
import PainelParticipanteCategoria from './pages/ParticipanteCategoria/PainelParticipanteCategoria';
import CadastroParticipanteCategoria from './pages/ParticipanteCategoria/CadastroParticipanteCategoria';
import AlterarContaFornecedor from './pages/Financeiro/Parcela/AlterarContaFornecedor';
import CadastroEventoDiaQuantidade from './pages/Evento/CadastroEventoDiaQuantidade';
import CadastroEventoAgenda from './pages/Evento/CadastroEventoAgenda';
import eventoImportarOpcaoAereo from './pages/Evento/EventoImportarOpcaoAereo';
import EventoImportarKpi from './pages/Evento/EventoImportarKpi';
import EventoImportarParticipante from './pages/Evento/EventoImportarParticipante';
import EventoImportarPerguntasSatisfacao from './pages/Evento/EventoImportarPerguntasSatisfacao';
import EventoImportarAereo from './pages/Evento/EventoImportarAereo';
import EventoImportarHospedagem from './pages/Evento/EventoImportarHospedagem';
import EventoImportarInscricoes from './pages/Evento/EventoImportarInscricoes';
import EventoImportarCartas from './pages/Evento/EventoImportarCartas';
import EventoImportarSanofi from './pages/Evento/EventoImportarSanofi';
import PainelEventoCarta from './pages/Evento/Carta/PainelEventoCarta';
import PainelEventoChecklistModelo from './pages/Evento/ChecklistModelo/PainelEventoChecklistModelo';
import PainelProdutoBu from './pages/ProdutoBu/PainelProdutoBu';
import PainelProdutoUnidadeNegocio from './pages/ProdutoUnidadeNegocio/PainelProdutoUnidadeNegocio';
import PainelProdutoLinha from './pages/ProdutoLinha/PainelProdutoLinha';
import CadastroProdutoBu from './pages/ProdutoBu/CadastroProdutoBu';
import CadastroProdutoUnidadeNegocio from './pages/ProdutoUnidadeNegocio/CadastroProdutoUnidadeNegocio';
import CadastroProdutoLinha from './pages/ProdutoLinha/CadastroProdutoLinha';
import PainelEventoResponsavelCliente from './pages/Evento/PainelEventoResponsavelCliente';
import CadastroEventoResponsavelCliente from './pages/Evento/CadastroEventoResponsavelCliente';
import PainelEventoHistorico from './pages/Evento/PainelEventoHistorico';
import RelatorioDre from './pages/Financeiro/RelatorioDre';
import CadastroEventoPerguntaQrCode from './pages/Evento/CadastroEventoPerguntaQrCode';
import CadastroEventoRsvp from './pages/Evento/CadastroEventoRsvp';
import CadastroEventoRsvpOpcaoAereo from './pages/Evento/CadastroEventoRsvpOpcaoAereo';

import PainelEventoPalestrante from './pages/Evento/Palestrante/PainelEventoPalestrante';
import CadastroEventoPalestrante from './pages/Evento/Palestrante/CadastroEventoPalestrante';

import PainelEventoParticipante from './pages/Evento/Participante/PainelEventoParticipante';
import CadastroEventoParticipante from './pages/Evento/Participante/CadastroEventoParticipante';
import CadastroEventoParticipanteAssinatura from './pages/Evento/Participante/CadastroEventoParticipanteAssinatura';
import CadastroEventoParticipanteConvite from './pages/Evento/Participante/CadastroEventoParticipanteConvite';
import CadastroEventoParticipanteAereoConvite from './pages/Evento/Participante/CadastroEventoParticipanteAereoConvite';
import CadastroEventoParticipanteAereoPreencherConvite from './pages/Evento/Participante/CadastroEventoParticipanteAereoPreencherConvite';
import CadastroEventoParticipanteTransferConvite from './pages/Evento/Participante/CadastroEventoParticipanteTransferConvite';
import CadastroEventoParticipanteHotelConvite from './pages/Evento/Participante/CadastroEventoParticipanteHotelConvite';
import CadastroEventoParticipanteLista from './pages/Evento/Participante/CadastroEventoParticipanteLista';
import CadastroEventoParticipanteListaConcluido from './pages/Evento/Participante/CadastroEventoParticipanteListaConcluido';
import CadastroEventoParticipanteConviteConcluido from './pages/Evento/Participante/CadastroEventoParticipanteConviteConcluido';
import CadastroEventoParticipanteConviteRecusado from './pages/Evento/Participante/CadastroEventoParticipanteConviteRecusado';
import RelatorioListaPresenca from './pages/Rsvp/RelatorioListaPresenca';
import CadastroEventoParticipanteOperador from './pages/Evento/Participante/CadastroEventoParticipanteOperador';
import PainelEventoParticipanteSituacao from './pages/Evento/Participante/Situacao/PainelEventoParticipanteSituacao';
import CadastroEventoParticipanteSituacao from './pages/Evento/Participante/Situacao/CadastroEventoParticipanteSituacao';
import EventoVincularParticipanteOperador from './pages/Evento/Participante/EventoVincularParticipanteOperador';
import CadastroEventoCarta from './pages/Evento/Carta/CadastroEventoCarta';
import CadastroEventoChecklistModelo from './pages/Evento/ChecklistModelo/CadastroEventoChecklistModelo';
import CadastroEventoChecklist from './pages/Evento/Checklist/CadastroEventoChecklist';
import EventoRelatorioParticipante from './pages/Evento/EventoRelatorioParticipante';
import RelatorioPagamentoOrcamento from './pages/Orcamento/RelatorioPagamentoOrcamento';
import ExportarEventoExecucao from './pages/Evento/Bi/ExportarEventoExecucao';
import ImportarOrcamento from './pages/Orcamento/ImportarOrcamento';
import PainelChecklist from './pages/Evento/Checklist/PainelChecklist';
import CadastroEventoParticipanteAceitarTermo from './pages/Evento/Participante/CadastroEventoParticipanteAceitarTermo';
import CadastroEventoParticipanteAceitarConvite from './pages/Evento/Participante/CadastroEventoParticipanteAceitarConvite';
import CadastroEventoParticipanteCartaInformativa from './pages/Evento/Participante/CadastroEventoParticipanteCartaInformativa';
import CadastroEventoParticipanteContratoEnviado from './pages/Evento/Participante/CadastroEventoParticipanteContratoEnviado';
import EventoRelatorio from './pages/Evento/EventoRelatorio';
import PainelEventoOrdemServico from './pages/Evento/PainelEventoOrdemServico';
import CadastroEventoOrdemServico from './pages/Evento/CadastroEventoOrdemServico';
import CadastroEventoOrdemServicoResumo from './pages/Evento/CadastroEventoOrdemServicoResumo';
import AlterarEventoChecklist from './pages/Evento/Checklist/AlterarEventoChecklist';
import PainelChecklistItens from './pages/Evento/Checklist/PainelChecklistItens';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={localStorage.getItem("token") ? Principal : Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/loginEvento/:id?" exact component={LoginEvento} />
        <Route path="/loginConfirmarDados/:id?" exact component={LoginConfirmarDados} />
        <Route path="/loginRapido/:id/:rota" exact component={LoginRapido} />
        <Route path="/registro" component={Registro} />

        <PrivateRoute path="/graficos" component={Graficos} />
        <PrivateRoute path="/principal" component={Principal} />

        <PrivateRoute path="/financeiro/alterarContaFornecedor" component={AlterarContaFornecedor} />
        <PrivateRoute path="/financeiro" component={PainelFinanceiro} />

        <PrivateRoute path="/briefing" component={PainelBriefing} />
        <PrivateRoute path="/listaBriefingCliente" component={ListaBriefingCliente} />
        <PrivateRoute path="/briefingCliente" component={PainelBriefingCliente} />
        <PrivateRoute path="/cadastroModeloBriefing" component={CadastroModeloBriefing} />
        <PrivateRoute path="/visualizarBriefing/:id?" component={VisualizarBriefing} />
        <PrivateRoute path="/cadastroBriefingAdvisoryBoard/:id?" component={CadastroBriefingAdvisoryBoard} />
        <PrivateRoute path="/cadastroBriefingEventoTerceiro/:id?" component={CadastroBriefingEventoTerceiro} />
        <PrivateRoute path="/cadastroBriefingOrganizadoSede/:id?" component={CadastroBriefingOrganizadoSede} />
        <PrivateRoute path="/cadastroBriefingOrganizadoForcaVendas/:id?" component={CadastroBriefingOrganizadoForcaVendas} />

        <PrivateRoute path="/eventoPergunta" component={PainelEventoPergunta} />
        <PrivateRoute path="/eventoPerguntaQrCode/:id?" component={CadastroEventoPerguntaQrCode} />

        <PrivateRoute path="/eventoSatisfacaoPergunta" component={PainelEventoSatisfacaoPergunta} />
        <PrivateRoute path="/cadastroEventoSatisfacaoPergunta/:id?" component={CadastroEventoSatisfacaoPergunta} />

        <PrivateRoute path="/eventoOrdemServico" component={PainelEventoOrdemServico} />
        <PrivateRoute path="/cadastroEventoOrdemServico/:idEvento?" component={CadastroEventoOrdemServico} />

        <PrivateRoute path="/evento" component={PainelEvento} />
        <PrivateRoute path="/cadastroEvento/:id?" component={CadastroEvento} />
        <PrivateRoute path="/cadastroEventoPergunta/:id?" component={CadastroEventoPergunta} />
        <PrivateRoute path="/cadastroEventoRsvp/:id?" component={CadastroEventoRsvp} />
        <PrivateRoute path="/cadastroEventoRsvpOpcaoAereo/:id?" component={CadastroEventoRsvpOpcaoAereo} />
        <PrivateRoute path="/cadastroEventoDiaQuantidade/:id?" component={CadastroEventoDiaQuantidade} />
        <PrivateRoute path="/cadastroEventoAgenda/:id?" component={CadastroEventoAgenda} />
        <PrivateRoute path="/eventoImportarOpcaoAereo/:id?" component={eventoImportarOpcaoAereo} />
        <PrivateRoute path="/eventoImportarKpi" component={EventoImportarKpi} />
        <PrivateRoute path="/eventoImportarSanofi" component={EventoImportarSanofi} />
        <PrivateRoute path="/eventoImportarParticipante/:id?" component={EventoImportarParticipante} />
        <PrivateRoute path="/eventoImportarPerguntasSatisfacao/:id?" component={EventoImportarPerguntasSatisfacao} />
        <PrivateRoute path="/eventoRelatorioParticipante/:id?" component={EventoRelatorioParticipante} />
        <PrivateRoute path="/eventoImportarAereo/:id?" component={EventoImportarAereo} />
        <PrivateRoute path="/eventoImportarHospedagem/:id?" component={EventoImportarHospedagem} />
        <PrivateRoute path="/eventoImportarInscricoes/:id?" component={EventoImportarInscricoes} />
        <PrivateRoute path="/eventoImportarCartas/:id?" component={EventoImportarCartas} />
        <PrivateRoute path="/eventoResponsavelCliente" component={PainelEventoResponsavelCliente} />
        <PrivateRoute path="/eventoParticipanteSituacao" component={PainelEventoParticipanteSituacao} />
        <PrivateRoute path="/cadastroEventoParticipanteSituacao/:id?" component={CadastroEventoParticipanteSituacao} />
        <PrivateRoute path="/cadastroEventoResponsavelCliente/:id?" component={CadastroEventoResponsavelCliente} />
        <PrivateRoute path="/eventoHistorico/:id?" component={PainelEventoHistorico} />

        <PrivateRoute path="/eventoPalestrante/:id?" component={PainelEventoPalestrante} />
        <PrivateRoute path="/cadastroEventoPalestrante/:id?" component={CadastroEventoPalestrante} />

        <PrivateRoute path="/eventoParticipante/:id?" component={PainelEventoParticipante} />
        <PrivateRoute path="/cadastroEventoOrdemServicoResumo/:id?" component={CadastroEventoOrdemServicoResumo} />
        <PrivateRoute path="/relatorioListaPresenca/:idEvento?" component={RelatorioListaPresenca} />
        <PrivateRoute path="/eventoVincularParticipanteOperador/:id?" component={EventoVincularParticipanteOperador} />
        <PrivateRoute path="/cadastroEventoParticipante/:id?" component={CadastroEventoParticipante} />
        <PrivateRoute path="/cadastroEventoParticipanteAssinatura/:id?" component={CadastroEventoParticipanteAssinatura} />
        <PrivateRoute path="/cadastroEventoParticipanteAceitarTermo/:id?" component={CadastroEventoParticipanteAceitarTermo} />
        <PrivateRoute path="/cadastroEventoParticipanteAceitarConvite/:id?" component={CadastroEventoParticipanteAceitarConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteCartaInformativa/:id?" component={CadastroEventoParticipanteCartaInformativa} />
        <PrivateRoute path="/cadastroEventoParticipanteConvite/:id?" component={CadastroEventoParticipanteConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteAereoConvite/:id?" component={CadastroEventoParticipanteAereoConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteAereoPreencherConvite/:id?" component={CadastroEventoParticipanteAereoPreencherConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteTransferConvite/:id?" component={CadastroEventoParticipanteTransferConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteHotelConvite/:id?" component={CadastroEventoParticipanteHotelConvite} />
        <PrivateRoute path="/cadastroEventoParticipanteLista/:id?" component={CadastroEventoParticipanteLista} />
        <PrivateRoute path="/cadastroEventoParticipanteListaConcluido/:id?" component={CadastroEventoParticipanteListaConcluido} />
        <PrivateRoute path="/cadastroEventoParticipanteConviteConcluido/:id?" component={CadastroEventoParticipanteConviteConcluido} />
        <PrivateRoute path="/cadastroEventoParticipanteContratoEnviado/:id?" component={CadastroEventoParticipanteContratoEnviado} />
        <PrivateRoute path="/cadastroEventoParticipanteConviteRecusado/:id?" component={CadastroEventoParticipanteConviteRecusado} />
        <PrivateRoute path="/cadastroEventoParticipanteOperador" component={CadastroEventoParticipanteOperador} />
        <PrivateRoute path="/exportarEventoExecucao" component={ExportarEventoExecucao} />
        <PrivateRoute path="/relatorioevento" component={EventoRelatorio} />


        <PrivateRoute path="/eventoCarta" component={PainelEventoCarta} />
        <PrivateRoute path="/cadastroEventoCarta/:id?" component={CadastroEventoCarta} />

        <PrivateRoute path="/checklist" component={PainelChecklist} />
        <PrivateRoute path="/checklistItens" component={PainelChecklistItens} />
        <PrivateRoute path="/eventoChecklistModelo" component={PainelEventoChecklistModelo} />
        <PrivateRoute path="/cadastroEventoChecklistModelo/:id?" component={CadastroEventoChecklistModelo} />
        <PrivateRoute path="/cadastroEventoChecklist" component={CadastroEventoChecklist} />
        <PrivateRoute path="/alterarEventoChecklist/:id?" component={AlterarEventoChecklist} />

        <PrivateRoute path="/rsvp" component={PainelRsvp} />

        <PrivateRoute path="/produto" component={PainelProduto} />
        <PrivateRoute path="/cadastroProduto/:id?" component={CadastroProduto} />
        <PrivateRoute path="/produtoGrupo" component={PainelProdutoGrupo} />
        <PrivateRoute path="/produtoBu" component={PainelProdutoBu} />
        <PrivateRoute path="/produtoUnidadeNegocio" component={PainelProdutoUnidadeNegocio} />
        <PrivateRoute path="/produtoLinha" component={PainelProdutoLinha} />
        <PrivateRoute path="/cadastroProdutoGrupo/:id?" component={CadastroProdutoGrupo} />
        <PrivateRoute path="/cadastroProdutoBu/:id?" component={CadastroProdutoBu} />
        <PrivateRoute path="/cadastroProdutoUnidadeNegocio/:id?" component={CadastroProdutoUnidadeNegocio} />
        <PrivateRoute path="/cadastroProdutoLinha/:id?" component={CadastroProdutoLinha} />
        <PrivateRoute path="/produtoTipoAtributo" component={PainelProdutoTipoAtributo} />
        <PrivateRoute path="/cadastroProdutoTipoAtributo/:id?" component={CadastroProdutoTipoAtributo} />
        <PrivateRoute path="/produtoAtributo" component={PainelProdutoAtributo} />
        <PrivateRoute path="/cadastroProdutoAtributo/:id?" component={CadastroProdutoAtributo} />

        <PrivateRoute path="/produtoOrcamento" component={PainelProdutoOrcamento} />
        <PrivateRoute path="/cadastroProdutoOrcamento/:id?" component={CadastroProdutoOrcamento} />

        <PrivateRoute path="/servicoOrcamento" component={PainelServicoOrcamento} />
        <PrivateRoute path="/cadastroServicoOrcamento/:id?" component={CadastroServicoOrcamento} />

        <PrivateRoute path="/rhCargo" component={PainelRhCargo} />
        <PrivateRoute path="/cadastroRhCargo/:id?" component={CadastroRhCargo} />

        <PrivateRoute path="/cliente" component={PainelCliente} />
        <PrivateRoute path="/cadastroCliente/:id?" component={CadastroCliente} />

        <PrivateRoute path="/Fornecedor" component={PainelFornecedor} />
        <PrivateRoute path="/cadastroFornecedor/:id?" component={CadastroFornecedor} />

        <PrivateRoute path="/participanteCategoria" component={PainelParticipanteCategoria} />
        <PrivateRoute path="/cadastroParticipanteCategoria/:id?" component={CadastroParticipanteCategoria} />

        <PrivateRoute path="/pessoa" component={PainelPessoa} />
        <PrivateRoute path="/cadastroPessoa/:id?" component={CadastroPessoa} />

        <PrivateRoute path="/sla" component={PainelSla} />
        <PrivateRoute path="/cadastroSla/:id?" component={CadastroSla} />

        <PrivateRoute path="/slaModelo" component={PainelSlaModelo} />
        <PrivateRoute path="/cadastroSlaModelo/:id?" component={CadastroSlaModelo} />

        <PrivateRoute path="/venda" component={PainelVenda} />
        <PrivateRoute path="/cadastroVenda/:id?" component={CadastroVenda} />
        <PrivateRoute path="/relatorioCompraVenda" component={RelatorioCompraVenda} />
        <PrivateRoute path="/relatorioCompra" component={RelatorioCompra} />

        <PrivateRoute path="/orcamento" component={PainelOrcamento} />
        <PrivateRoute path="/cadastroOrcamento/:id?" component={CadastroOrcamento} />
        <PrivateRoute path="/importarOrcamento" component={ImportarOrcamento} />
        <PrivateRoute path="/cadastroPagamentoOrcamento/:id?" component={CadastroPagamentoOrcamento} />
        <PrivateRoute path="/orcamentoRelatorioPagamento" component={RelatorioPagamentoOrcamento} />

        <PrivateRoute path="/compra" component={PainelCompra} />
        <PrivateRoute path="/cadastroCompra/:id?" component={CadastroCompra} />

        <PrivateRoute path="/financeiroParcelaPagar" component={PainelParcelaPagar} />
        <PrivateRoute path="/cadastroParcelaPagar/:id?" component={CadastroParcelaPagar} />

        <PrivateRoute path="/financeiroRelatorioDre" component={RelatorioDre} />

        <PrivateRoute path="/financeiroParcelaReceber" component={PainelParcelaReceber} />
        <PrivateRoute path="/cadastroParcelaReceber/:id?" component={CadastroParcelaReceber} />
        <PrivateRoute path="/financeiroParcelaRelatorio" component={RelatorioFinanceiroParcela} />

        <PrivateRoute path="/financeiroPlanoConta" component={PainelPlanoConta} />
        <PrivateRoute path="/cadastroPlanoConta/:id?" component={CadastroPlanoConta} />

        <PrivateRoute path="/financeiroCaixa" component={PainelCaixa} />
        <PrivateRoute path="/cadastroCaixa/:id?" component={CadastroCaixa} />

        <PrivateRoute path="/slaTipo" component={PainelSlaTipo} />
        <PrivateRoute path="/cadastroSlaTipo/:id?" component={CadastroSlaTipo} />

        <PrivateRoute path="/slaCompromisso" component={PainelSlaCompromisso} />
        <PrivateRoute path="/cadastroSlaCompromisso/:id?" component={CadastroSlaCompromisso} />

        <PrivateRoute path="/eventoChecklistAtividade" component={PainelEventoChecklistAtividade} />
        <PrivateRoute path="/cadastroEventoChecklistAtividade/:id?" component={CadastroEventoChecklistAtividade} />

        <PrivateRoute path="/slaGrafico/:ids" component={PainelSlaGrafico} />

        <PrivateRoute path="/empresa" component={PainelEmpresa} />
        <PrivateRoute path="/cadastroEmpresa/:id?" component={CadastroEmpresa} />

      </Switch>
    </BrowserRouter>
  );
}