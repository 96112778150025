import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBr from "date-fns/locale/pt-BR";
import moment from 'moment';
import CustomInput from './CustomInput';
import { FaPlus, FaTrash } from "react-icons/fa";

export default function ListaDatas({
    id = null,
    label = "Data",
    initialDates = [],
    onChange = null,
    disabled = false
}) {
    const [datas, setDatas] = useState(initialDates);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleAddDate = (e) => {
        e.preventDefault();

        if (selectedDate) {
            if (datas.includes(selectedDate)) {
                setSelectedDate(null);
                return;
            }

            const novasDatas = [...datas, selectedDate];
            setDatas(novasDatas);
            setSelectedDate(null);
            if (onChange) {
                onChange(novasDatas);
            }
        }
    };

    const handleRemoveDate = (index) => {
        const novasDatas = datas.filter((_, idx) => idx !== index);
        setDatas(novasDatas);
        if (onChange) {
            onChange(novasDatas);
        }
    };

    const selected = selectedDate ? moment(selectedDate, "DD/MM/YYYY").toDate() : null;

    return (
        <div className="lista-datas-container">
            <div className="data-input-container d-flex align-items-center" style={{ marginBottom: '10px' }}>
                <DatePicker
                    disabled={disabled}
                    id={id}
                    selected={selected}
                    locale={ptBr}
                    onChange={(data) => {
                        setSelectedDate(moment(data).format('DD/MM/YYYY'));
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={label}
                    popperPlacement="top-end"
                    customInput={
                        <CustomInput
                            disabled={disabled}
                            id={id}
                            label={label}
                            placeholder={label}
                        />
                    }
                />
                <button
                    type="button"
                    onClick={handleAddDate}
                    disabled={!selectedDate || disabled}
                    className="btn btn-primary btn-sm ms-2"
                >
                    <FaPlus />Adicionar Data
                </button>
            </div>

            <div className="datas-list">
                {datas.map((data, index) => (
                    <div key={index} className="data-item d-flex align-items-center" style={{ marginBottom: '5px' }}>
                        <span>{data}</span>
                        <button
                            type="button"
                            onClick={() => handleRemoveDate(index)}
                            disabled={disabled}
                            className="btn btn-danger btn-sm ms-2"
                            title="Remover"
                        >
                            <FaTrash />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
} 