import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave, FaTrash, FaPlus, FaUpload } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import { data, dataMysql, pontoVirgula } from '../../util/util';
import NavItem from '../../components/NavItem';
import Data from '../../components/Data';
import ReactInputMask from 'react-input-mask';

export default function CadastroEventoOrdemServicoResumo({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [resumos, setResumos] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            try {
                api({
                    method: "get",
                    action: '/eventoOrdemServicoResumo/' + id,
                    token: token,
                }).then(response => {
                    const dadosRecebidos = response.data.data;
                    setResumos(dadosRecebidos);
                });
            } catch (err) {
                toast.error('Erro ao carregar dados: ' + (err.response?.data?.error ?? err.message));
            }
        }
    };

    const salvar = () => {
        let dadosSalvar = { ...resumos };
        const metodo = 'put';
        const acao = `/eventoOrdemServicoResumo/${id}`;

        api({
            method: metodo,
            action: acao,
            token: token,
            data: {
                dados: dadosSalvar,
            }
        })
            .then(() => {
                toast.success('Resumo salvo com sucesso!');
                history.push("/eventoOrdemServico?idEvento=" + id);
            })
            .catch((error) => {
                toast.error('Erro ao salvar Resumo: ' + (error.response?.data?.error ?? error.message));
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);
    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Resumo da Ordem de Serviço</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { e.preventDefault(); salvar(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50px"></th>
                                                                            <th>Salas</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resumos.map((resumo, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IconButton
                                                                                        title="Remover"
                                                                                        color="warning"
                                                                                        icon={<FaTrash size={20} />}
                                                                                        onClick={() => {
                                                                                            const novoResumos = [...resumos];
                                                                                            novoResumos.splice(index, 1);
                                                                                            setResumos(novoResumos);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Título"
                                                                                                    value={resumo.nome}
                                                                                                    onChange={(e) => {
                                                                                                        const novoResumos = [...resumos];
                                                                                                        novoResumos[index].nome = e.target.value;
                                                                                                        setResumos(novoResumos);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Título</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Valor"
                                                                                                    value={pontoVirgula(resumo.valor)}
                                                                                                    onChange={(e) => {
                                                                                                        const novoResumos = [...resumos];
                                                                                                        novoResumos[index].valor = e.target.value;
                                                                                                        setResumos(novoResumos);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Imposto"
                                                                                                    value={pontoVirgula(resumo.imposto)}
                                                                                                    onChange={(e) => {
                                                                                                        const novoResumos = [...resumos];
                                                                                                        novoResumos[index].imposto = e.target.value;
                                                                                                        setResumos(novoResumos);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Imposto</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Taxa de Serviço"
                                                                                                    value={pontoVirgula(resumo.taxaservico)}
                                                                                                    onChange={(e) => {
                                                                                                        const novoResumos = [...resumos];
                                                                                                        novoResumos[index].taxaservico = e.target.value;
                                                                                                        setResumos(novoResumos);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Taxa de Serviço</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-floating form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Observação"
                                                                                            value={resumo.observacao}
                                                                                            onChange={(e) => {
                                                                                                const novoResumos = [...resumos];
                                                                                                novoResumos[index].observacao = e.target.value;
                                                                                                setResumos(novoResumos);
                                                                                            }}
                                                                                        />
                                                                                        <label htmlFor="floatingInput">Observação</label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            setResumos([...resumos, {}]);
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Item
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}