import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract, pontoVirgula } from '../../../util/util';
import { toast } from 'react-toastify';
import ItemChecklist from '../../../components/ItemChecklist';

export default function CadastroEventoChecklist() {
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [reload, setReload] = useState(1);
    const history = useHistory();

    const validar = () => {
        var evento = document.querySelector("#evento");
        if (dados.evento === undefined || dados.evento === null) {
            toast.error('É necessário selecionar o evento');
            setTimeout(() => {
                evento.focus();
            }, 500);
            return false;
        }

        var modelo = document.querySelector("#modelo");
        if (dados.modelo === undefined || dados.modelo === null) {
            toast.error('É necessário selecionar o modelo de checklist');
            setTimeout(() => {
                modelo.focus();
            }, 500);
            return false;
        }
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        api({
            method: 'post',
            action: '/eventoChecklist',
            token: token,
            data: {
                ...dados,
            }
        })
            .then(response => {
                history.push("/alterarEventoChecklist/" + response.data.data.id);
            })
            .catch(function (error) {
                toast.error('Erro ao iniciar o checklist: ' + error.response.data.error);
            });
    };

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Novo Checklist</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            id="evento"
                                                            placeholder={"Evento"}
                                                            value={{ value: dados.evento, label: dados.nomeEvento }}
                                                            onChange={(e) => {
                                                                dados.evento = e.value;
                                                                dados.nomeEvento = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label,
                                                                                disabled: registro.situacao === "fechado" ? true : false,
                                                                                cliente: registro.cliente ?? null
                                                                            }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Modelo</label>
                                                        <ReactSelect
                                                            id="modelo"
                                                            value={{ value: dados.modelo ?? "", label: dados.nomeModelo ?? "" }}
                                                            onChange={(e) => {
                                                                dados.modelo = e.value;
                                                                dados.nomeModelo = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoChecklistModelo',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + "-" + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "10px" }}>
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            salvar();
                                                        }}>
                                                        <FaSave size={20} /> Iniciar
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}