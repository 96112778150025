import React from 'react';
import { BsCardChecklist } from 'react-icons/bs';
import { FaUser, FaUsers, FaHome, FaCalendar, FaFileAlt, FaMoneyBillAlt, FaBuilding, FaTruck, FaChartArea, FaPlaneDeparture, FaQuestion, FaThumbsUp } from 'react-icons/fa';
import { FiHeadphones } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';

export default function SideBar() {
    const location = useLocation();
    const perfil = localStorage.getItem("perfil");
    return (
        <div id="sidebar" className="active no-print">
            <div className="sidebar-wrapper active ps ps--active-y">
                <div className="sidebar-menu">
                    <ul className="menu">
                        {['rsvp', 'operadorrsvp'].includes(perfil) ?
                            <MenuItem to="/cadastroEventoParticipanteOperador" location={location.pathname} icon={<FiHeadphones size={20} />}>Operador</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                            <MenuItem to="/graficos" location={location.pathname} icon={<FaChartArea size={20} />}>Gráficos</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'rsvp'].includes(perfil) ?
                            <MenuItem to="/pessoa" location={location.pathname} icon={<FaUser size={20} />}>Usuários</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'aereo', 'gerentebackoffice', 'backoffice', 'rsvp'].includes(perfil) ?
                            <MenuItem to="/cliente" location={location.pathname} icon={<FaUsers size={20} />}>Clientes</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'gerentebackoffice', 'evento', 'aereo'].includes(perfil) ?
                            <MenuItem to="/fornecedor" location={location.pathname} icon={<FaTruck size={20} />}>Fornecedor</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'evento', 'gerentebackoffice', 'backoffice', 'rsvp', 'listarsvp'].includes(perfil) ?
                            <MenuItem to="/evento" location={location.pathname} icon={<FaCalendar size={20} />}>Eventos</MenuItem> :
                            null}
                        {['diretor', 'briefing', 'cliente', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                            <MenuItem to="/briefing" location={location.pathname} icon={<FaFileAlt size={20} />}>Briefing</MenuItem> :
                            null}
                        {['diretor', 'gerenteevento', 'evento'].includes(perfil) ?
                            <MenuItem to="/eventoPergunta" location={location.pathname} icon={<FaQuestion size={20} />}>Q&A</MenuItem> :
                            null}
                        {['diretor', 'gerenteevento', 'evento'].includes(perfil) ?
                            <MenuItem to="/eventoSatisfacaoPergunta" location={location.pathname} icon={<FaThumbsUp size={20} />}>Satisfação</MenuItem> :
                            null}

                        {['diretor', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                            <MenuItem to="/checklist" location={location.pathname} icon={<BsCardChecklist size={20} />}>Checklist</MenuItem> :
                            null}
                        {['diretor', 'gerenteevento', 'evento'].includes(perfil) ?
                            <MenuItem to="/eventoOrdemServico" location={location.pathname} icon={<FaFileAlt size={20} />}>Ordem de Serviço</MenuItem> :
                            null}
                        {['diretor', 'gerentebackoffice', 'backoffice'].includes(perfil) ?
                            <MenuItem to="/sla" location={location.pathname} icon={<BsCardChecklist size={20} />}>SLA</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                            <MenuItem to="/orcamento" location={location.pathname} icon={<FaFileAlt size={20} />}>Orçamento</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                            <MenuItem to="/financeiro" location={location.pathname} icon={<FaMoneyBillAlt size={20} />}>Financeiro</MenuItem> :
                            null}
                        {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                            <MenuItem to="/empresa" location={location.pathname} icon={<FaBuilding size={20} />}>Empresas</MenuItem> :
                            null}
                    </ul>
                </div>
            </div>
        </div>
    );
}

