import React, { useState } from "react";
import { useEffect } from "react/cjs/react.development";
import { Item } from "semantic-ui-react";
import { FaPlus, FaTrash } from 'react-icons/fa';
import api from '../services/api';
import IconButton from "./IconButton";
import ReactSelect from "./ReactSelect";

export default function ItemChecklistEditavel({ itens, stateChanger }) {
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});

    if (itens !== undefined && itens.length > 0) {
        return <>{itens.map((item, index) => {
            return <>
                <div style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "10px",
                    border: "1px solid #dfe3e7",
                    borderRadius: "5px"
                }}>
                    <div>
                        <IconButton
                            style={{ marginRight: "5px" }}
                            small={true}
                            icon={<FaTrash size={20} />}
                            color={"warning"}
                            onClick={() => {
                                itens.splice(index, 1);
                                stateChanger(Math.random());
                            }} />
                        <div style={{ display: 'inline-block', minWidth: "300px" }}>
                            <ReactSelect
                                value={{ value: itens[index].atividade?.id ?? "", label: itens[index].atividade?.nome ?? "" }}
                                onChange={(e) => {
                                    itens[index].atividade.id = e.value;
                                    itens[index].atividade.nome = e.label;
                                    setDados({ value: itens[index].atividade.id, label: itens[index].atividade.nome });
                                }}
                                loadOptions={(input) => {
                                    return api({
                                        method: 'get',
                                        action: '/eventoChecklistAtividade',
                                        token: token,
                                        params: { nome: input }
                                    })
                                        .then(response => {
                                            let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + "-" + registro.nome }));
                                            return options;
                                        })
                                        .catch(function (error) {
                                            //console.log(error);
                                        });
                                }}
                            />
                        </div>
                        <ItemChecklistEditavel itens={item.itens} stateChanger={stateChanger} />
                    </div>
                </div>
                <div>
                    <IconButton
                        style={{ marginRight: "5px" }}
                        title="Adicionar item aqui"
                        small={true}
                        icon={<FaPlus size={20} />}
                        color={"info"}
                        onClick={() => {
                            itens.splice(index + 1, 0, {
                                atividade: { id: "", nome: "" },
                                itens: []
                            });
                            stateChanger(Math.random());
                        }} />
                </div>
            </>;
        })}
        </>;
    } else {
        return <IconButton
            style={{ marginLeft: "5px" }}
            title="Adicionar item dentro deste item"
            small={true}
            icon={<FaPlus size={20} />}
            color={"info"}
            onClick={() => {
                if (!Array.isArray(itens)) {
                    itens = [];
                }
                itens.push({
                    atividade: { id: "", nome: "" },
                    itens: []
                });
                stateChanger(Math.random());
            }} />;
    }
}