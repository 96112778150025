import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaList, FaPlus, FaSearch } from 'react-icons/fa';
import NavItem from '../../../components/NavItem';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import ReactSelect from '../../../components/ReactSelect';
import IconButton from '../../../components/IconButton';
import { BsCardChecklist } from 'react-icons/bs';

export default function PainelChecklistItens() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({ prazo: "2" });
    const [dados, setDados] = useState({});
    const [checklistItems, setChecklistItems] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });

        api({
            method: 'get',
            action: '/eventoChecklist',
            token: token,
            params: {
                ...filtro,
                ...{
                    "naoMostrarModelos": true,
                    "situacao": 1
                },
            }
        })
            .then(response => {
                setChecklistItems(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil) ?
                        <NavItem to={"/cadastroEventoChecklist/"} icon={<FaPlus size={20} />} color="primary">Novo Checklist</NavItem> :
                        null}
                    {['diretor'].includes(perfil) ?
                        <NavItem to="/eventoChecklistModelo" icon={<FaList size={20} />} color="outline-dark">Modelos de Checklist</NavItem> :
                        null}
                </ul>
                <div className="page-title">
                    <h3>Checklist</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado", "cancelado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Responsável</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.responsavel, label: filtro.nomeResponsavel }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "responsavel": e.value, "nomeResponsavel": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["pessoa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            id="prazo"
                                                            placeholder="2 dias"
                                                            value={filtro.prazo ?? null}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "prazo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Prazo (dias)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={true}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            loadOptions
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.nomeEvento ?? "",
                                                    sortable: true,
                                                    width: "250px",
                                                    cell: (row) => {
                                                        if (row.nomeEvento) {
                                                            if (row.identificacaoEvento) {
                                                                return row.codigoEvento + ' - ' + row.identificacaoEvento + ' - ' + row.nomeEvento ?? "";
                                                            } else {
                                                                return row.codigoEvento + ' - ' + row.nomeEvento ?? "";
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.nomeCliente ?? "",
                                                    sortable: true,
                                                    width: "200px",
                                                    cell: (row) => {
                                                        if (row.nomeCliente) {
                                                            if (row.nomeMunicipioCliente) { //tem municipio
                                                                return row.codigoCliente + ' - ' + row.nomeCliente + ' - ' + row.nomeMunicipioCliente + ' - ' + row.ufMunicipioCliente;
                                                            } else { //nao tem municipio
                                                                return row.codigoCliente + ' - ' + row.nomeCliente;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Responsável',
                                                    selector: row => row.nomeResponsavel ?? "",
                                                    sortable: true,
                                                    width: "150px",
                                                    cell: (row) => {
                                                        if (row.nomeResponsavel) {
                                                            return row.codigoResponsavel + ' - ' + row.nomeResponsavel;
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Atividade',
                                                    selector: row => row.nomeAtividade ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.nomeAtividade ?? "";
                                                    },
                                                },
                                                {
                                                    name: 'Prazo',
                                                    selector: row => row.distanciaEvento ?? "",
                                                    sortable: true,
                                                    width: "150px",
                                                    cell: (row) => {
                                                        if (row.distanciaEvento) {
                                                            if (row.distanciaEvento < 0) {
                                                                return <div style={{ color: "#e80d0d" }}>atraso de {row.distanciaEvento * -1} dias</div>;
                                                            } else {
                                                                return <div style={{ color: "#00AA00" }}>restam {row.distanciaEvento} dias</div>;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                            ]}
                                            data={checklistItems}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
