import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import ReactSelect from '../../components/ReactSelect';
import { useHistory } from 'react-router-dom';
import { pontoVirgula, buscarCep, extract } from '../../util/util';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

export default function CadastroEmpresa({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const history = useHistory();

    const preencherCep = (cep, dadosAtuais) => {
        buscarCep(cep).then((resposta) => {
            if (!resposta.erro) {
                setDados({
                    ...dadosAtuais,
                    ...{
                        enderecobairro: resposta.bairro,
                        enderecocomplemento: resposta.complemento,
                        enderecologradouro: resposta.logradouro,
                        enderecopais: "30",
                        nomeEnderecopais: "Brasil",
                        enderecomunicipio: resposta.ibge,
                        nomeEnderecomunicipio: resposta.localidade
                    }
                });
            }
        });
    }

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'enderecomunicipio',
                    ]
                });

                delete dadosRecebidos.tipoparticipante;

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {

        delete dados.banco;

        if (id) {//update

            delete dados.tipoparticipante;
            delete dados.aereomunicipioembarque;

            api({
                method: 'put',
                action: '/participante/' + id,
                token: token,
                data: dados,
            })
                .then(function (response) {
                    history.push("/empresa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/participante',
                token: token,
                data: { ...dados, ...{ tipoCadastro: "empresa" } }
            })
                .then(function (response) {
                    history.push("/empresa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Empresa</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label>Situação</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                                    onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                                    options={[
                                                                        { value: 'ativo', label: 'Ativo' },
                                                                        { value: 'inativo', label: 'Inativo' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nomefantasia" placeholder="Nome Fantasia"
                                                                    value={dados.nomefantasia}
                                                                    onChange={e => setDados({ ...dados, ...{ "nomefantasia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome Fantasia</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="cnpj" placeholder="CNPJ"
                                                                    value={dados.cnpj}
                                                                    onChange={e => setDados({ ...dados, ...{ "cnpj": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CNPJ</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="issaliquota" placeholder="Alíquota Impostos"
                                                                    value={pontoVirgula(dados.issaliquota)}
                                                                    onChange={e => setDados({ ...dados, ...{ "issaliquota": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Alíquota Impostos</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="endereco" title="Endereço">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecologradouro" placeholder="Rua / Logradouro"
                                                                    value={dados.enderecologradouro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecologradouro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Rua / Logradouro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="endereconumero" placeholder="Número"
                                                                    value={dados.endereconumero}
                                                                    onChange={e => setDados({ ...dados, ...{ "endereconumero": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Número</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocomplemento" placeholder="Complemento"
                                                                    value={dados.enderecocomplemento}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecocomplemento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Complemento</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocep" placeholder="CEP"
                                                                    value={dados.enderecocep}
                                                                    onChange={(e) => {
                                                                        dados.enderecocep = e.target.value;
                                                                        preencherCep(e.target.value, dados);
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">CEP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecobairro" placeholder="Bairro"
                                                                    value={dados.enderecobairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecobairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecomunicipio, label: dados.nomeEnderecomunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>País</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecopais, label: dados.nomeEnderecopais }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecopais": e.value, "nomeEnderecopais": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/pais',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}